import React from 'react';

const Weather = (props) => {

    let weatherIcon = null;
    let weatherTemp = null;

    if (props.data.icon !== 0) {
        const weather = props.data;
        weatherIcon = "https://openweathermap.org/img/wn/" + weather.weather[0].icon + ".png";
        weatherTemp = Math.round((weather.main.temp) * 10) / 10;
    };

    return (
        <>
            <img src={weatherIcon} alt="weather-icon" />{weatherTemp} °C
        </>
    );
};

export default Weather;