import React from 'react'

export default function Host(props) {
    
    let host_list = props.data
    
    const hostList = host_list.map((host, index) => {

        let hostArray;

        if (host.list) {
            hostArray = host.list.split(',');
        }

        return (
            <div key={index}>
                
                {host.state === "down" &&
                    <div className='card bg-down border border-danger border-2 mb-2 p-2 margin-card host-card blinks shadow'>

                    <div className='card-client position-absolute top-0 translate-middle' style={{backgroundImage: "url(/img/host-icon/" + host.hg_id + ".png)"}}></div>
                        <h2 className='fs-6 fw-bold m-0'>{host.hg_name}</h2>
                        <hr className='mb-2 mt-3'/>
                        <div className='row'>
                            <div className='col-12'>
                                {hostArray.map((host, index) => (
                                    <div className='m-0' key={index}><div className='point-red d-inline-block'></div><span className='ms-2 text-danger fw-bold'>{host}</span></div>
                                ))}
                            </div>
                        </div>
                    </div>
                }

            </div>
        )
    })

  return (
    <>
    
        {hostList}

        <div className='card border border-secondary border-2 mb-2 p-2 margin-card host-card shadow'>
            <h2 className='fs-6 fw-bold m-0'>Host OK</h2>
            <hr />
            <div className='row d-flex flex-row justify-content-center'>

            <div className='card-client position-absolute top-0 translate-middle bg-success text-center'><i className="bi bi-check2-all ok-icon"></i></div>
                {host_list.map((host, index) => (
                    host.state === "up" &&
                    <div key={index} className='card-client-ok m-1' style={{backgroundImage: "url(/img/host-icon/" + host.hg_id + ".png)"}}></div>
                ))}
            </div>
        </div>

    </>
  )
}
