import React from 'react';

const Table = (props) => {

    const alerts = props.data;

    return (
        <>
            <div className="card scroll p-2">
                <table className="table table-sm m-height">
                    <thead>
                        <tr><th>Host - Service</th><th>Output</th></tr>
                    </thead>
                    <tbody>
                        
                    {alerts.length > 0 ?

                    alerts.map((alert, index) => (
                            <tr key={index} className={alert.state === 1 ? "text-warning fw-bold fs-custom" : ( alert.state === 2 ? "text-danger fw-bold fs-custom" : "text-secondary fw-bold fs-custom")}>
                            <td>{alert.hostname} - {alert.servicename}</td><td>{alert.output}</td>
                        </tr>
                        ))
                    : <tr><td className="text-center fw-bold text-secondary" colSpan="2">Aucune alerte en cours</td></tr>}

                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Table;