import React, { useState, useEffect } from 'react';
import axios from '../../services/axios-psql';
import CounterCloud from './CounterCloud';
import { accountService } from '../../services/auth-service';

const Itop = ({headers}) => {

    const [ticket, setTicket] = useState({
        new: {
            value: 0,
            percent: 0
        },
        assigned: {
            value: 0,
            percent: 0
        },
        pending: {
            value: 0,
            percent: 0
        },
        total: {
            value: 0
        }
    })

    useEffect(() => {

        let loadData = () => {

            axios('/dashboard/ticketcount', headers)
            .then((alert) => {
                setTicket(alert.data);
            })
            .catch(e => {
                console.log(e);
                if (e.response.status === 401) {
                    accountService.logout();
                }
            });

        };

        loadData();
        const interval = setInterval(loadData, 1000 * 60 * 10);

        return () => clearInterval(interval);

    }, [headers]);

    return (
        // New Ticket
        <div className="row fixed-bottom m-1 mb-3">
            <div className="col-3">
                <div className="card shadow-sm">
                    <div className="card-body">
                    <div className="media d-flex">
                        <div className="media-body text-left">
                        <h3 className="new-ticket-textcolor fs-2">{ticket.new.value} ({Math.round((ticket.new.percent * 100) / 100)} %)</h3>
                        <span className='fs-4'>Nouveaux tickets</span>
                        </div>
                        <div className="position-absolute top-50 end-0 translate-middle-y pb-3 pe-3">
                            <i className="bi bi-ticket-perforated new-ticket-textcolor card-icon"></i>
                        </div>
                    </div>
                    <div className="progress mt-1 mb-0" style={{height: '7px'}}>
                        <div className="progress-bar progress-bar-striped progress-bar-animated new-ticket-bgcolor" role="progressbar" style={{width: ticket.new.percent + '%'}} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    </div>
                </div>
            </div>

            {/* Assigned Ticket */}
            <div className="col-3">
                <div className="card shadow-sm">
                    <div className="card-body">
                    <div className="media d-flex">
                        <div className="media-body text-left">
                        <h3 className="assigned-ticket-textcolor fs-2">{ticket.assigned.value} ({Math.round((ticket.assigned.percent * 100) / 100)} %)</h3>
                        <span className='fs-4'>Tickets assignés</span>
                        </div>
                        <div className="position-absolute top-50 end-0 translate-middle-y pb-3 pe-3">
                            <i className="assigned-ticket-textcolor bi bi-person-plus card-icon"></i>
                        </div>
                    </div>
                    <div className="progress mt-1 mb-0" style={{height: '7px'}}>
                        <div className="progress-bar progress-bar-striped progress-bar-animated assigned-ticket-bgcolor" role="progressbar" style={{width: ticket.assigned.percent + '%'}} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    </div>
                </div>
            </div>

            {/* Pending Ticket */}
            <div className="col-3">
                <div className="card me-2 shadow-sm">
                    <div className="card-body">
                    <div className="media d-flex">
                        <div className="media-body text-left">
                        <h3 className="pending-ticket-textcolor fs-2">{ticket.pending.value} ({Math.round((ticket.pending.percent * 100) / 100)} %)</h3>
                        <span className='fs-4'>Tickets en attentes</span>
                        </div>
                        <div className="position-absolute top-50 end-0 translate-middle-y pb-3 pe-3">
                            <i className="bi bi-pause-circle pending-ticket-textcolor card-icon"></i>
                        </div>
                    </div>
                    <div className="progress mt-1 mb-0" style={{height: '7px'}}>
                        <div className="progress-bar progress-bar-striped progress-bar-animated pending-ticket-bgcolor" role="progressbar" style={{width: ticket.pending.percent + '%'}} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    </div>
                </div>
            </div>

            <div className="col-3">
                <div className="card me-2 shadow-sm" style={{minHeight: '119.25px'}}>
                    <div className="card-body">
                        <div className="media d-flex">
                            <div className="media-body text-left">
                                <h3 className="cost-ticket-textcolor fs-2"><CounterCloud headers={headers} /></h3>
                                <span className='fs-4'>Coût en temps réel</span>
                            </div>
                            <div className="position-absolute top-50 end-0 translate-middle-y pb-3 pe-3">
                                <i className="bi bi-currency-euro cost-ticket-textcolor card-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Itop;
