import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import axios from '../../services/axios-psql';

const CounterCloud = ({headers}) => {
    let RefreshTimeEnSeconde = 3600
    let [money, setMoney] = useState({
        start_cost: 0,
        end_cost: 0,
        delta_time: 0
    });

    useEffect(() => {
        let loadData = () => {
            axios('/dashboard/cloudcount', headers).then((response) => {
                setMoney(response.data);
            });
        };

        loadData();
        const interval = setInterval(loadData, 1000 *  RefreshTimeEnSeconde);

        return () => clearInterval(interval);

    }, [headers, RefreshTimeEnSeconde]);

    let costbySeconde = ((money.end_cost - money.start_cost) / money.delta_time)
    let EndCostCalculated = parseInt(money.end_cost) + (costbySeconde * RefreshTimeEnSeconde) /* RefreshTimeEnMinuti car refresh du compteur toutes les 30 minutes*/

    return (
        <>
            <CountUp end={EndCostCalculated} start={money.end_cost} duration={RefreshTimeEnSeconde} decimals={4}/>
        </>
    );
};

export default CounterCloud;