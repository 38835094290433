import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import { Routes, Route } from 'react-router-dom';
import NotFound from './pages/NotFound';
import OciStats from './pages/OciStats';
import 'bootstrap-icons/font/bootstrap-icons.css'

// Route
import routes from './routes/routes';

// Service
import AuthGuard from './helpers/AuthGuard';


function App() {

  return (
    <>
      <Routes>
        {/* Login */}
        <Route path={routes.LOGIN} element={<Login />} />

        {/* Dashboard */}
        <Route path={routes.DASHBOARD} element={
          <AuthGuard>
            <Dashboard />
          </AuthGuard>
        } />

        {/* OCI Stats */}
        <Route path={routes.STATS} element={
          <AuthGuard>
            <OciStats />
          </AuthGuard>
        } />

        {/* Not found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;