const saveToken = (obj) => {
    localStorage.setItem('token', obj.token);
}

const logout = () => {
    localStorage.removeItem('token');
    window.location.reload(false);
}

const isLogged = () => {
    let token = localStorage.getItem('token');
    return !!token // Transforme la variable en booleen négatif
}

export const accountService = {
    saveToken, logout, isLogged
}