import { Navigate } from "react-router-dom";
import { accountService } from "../services/auth-service";
import routes from "../routes/routes";

export default function AuthGuard({children}) {

    if (!accountService.isLogged()) {
        return <Navigate to={routes.LOGIN} />
    }

    return children
}