import React, { useMemo } from 'react';
import Alert from '../components/Alert/Alert';
import Sidebar from '../components/Sidebar/Sidebar';
import Headers from '../components/Header/Headers';
import Itop from '../components/Itop/Itop';

const Dashboard = () => {

    const memoHeader = useMemo(() => ({headers: {
        'Authorization': localStorage.getItem('token')
    }}), []);

    return (
        <>
            <div className="row">
                <Headers />
            </div>
            <div className="row h-100 flex-fill">
                <div className="col-lg-11" style={{width: '97%'}}>
                    <Alert headers={memoHeader} />
                    <Itop headers={memoHeader} />
                </div>
                <div className="col-lg-1 bg-dark p-0 d-flex flex-column flex-shrink-0" style={{width: '3%'}}>
                    <Sidebar />
                </div>     
            </div>
        </>
    );

};

export default Dashboard;
