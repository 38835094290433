import React from 'react';

const Logo = () => {
    return (
        <div className="text-center p-1">
            <a className="fs-2" href="https://neodt.fr">
                <img src="/img/neodt-logo.png" width='50px' alt='logo' />
            </a>
        </div>
    );
};

export default Logo;