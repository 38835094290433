import axios from '../../services/axios-psql';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'moment/locale/fr';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { accountService } from '../../services/auth-service';

const GraphCostComp = (props) => {

    // Variable
    let select = props.select;
    let headers = props.headers;

    // Etat
    const [graphData, setGraphData] = useState([]);
    const [label, setLabel] = useState([]);
    const [total, setTotal] = useState(0);

    // Récupération des données depuis l'API
    useEffect(() => {

        let loadData = () => {

            axios.get('/ocistats/graphcost?tenancy=' + select, headers)
            .then((result) => {
                console.log(result.data)
                setTotal(result.data.total);
                setLabel(result.data.labels);
                setGraphData(result.data.res_data);
            })
            .catch(e => {
                console.log(e);
                if (e.response.status === 401) {
                    accountService.logout();
                }
            });
  
    };
  
        loadData();
        const interval = setInterval(loadData, 1000 * 60 * 720);
  
        return () => clearInterval(interval);

    }, [headers, select]);

    // Fonction injecter dans le contexte de la librairie cha, qui calcul le total
    const totalizer = {
        id: 'totalizer',
        beforeUpdate: chart => {
            let totals = {}
            let utmost = 0
            chart.data.datasets.forEach((dataset, datasetIndex) => {
            if (chart.isDatasetVisible(datasetIndex)) {
            utmost = datasetIndex
            dataset.data.forEach((value, index) => {
                totals[index] = (totals[index] || 0) + value
            })
        }   
    })

        chart.$totalizer = {
            totals: totals,
            utmost: utmost
            }
        }
    }

    // Data
    const data = {
        labels: label,
        datasets: graphData
    };
    
    const options = {
        interaction: {
            mode: "index",
            intersect: false,
        },
    
        plugins: {
            legend: {
            display: true,
            position: "top",
            align: "center",
            title: {
                display: true,
                text: "OCI Comparatif Mensuel",
                font: {
                    size: 18
                }
            },
            },
            datalabels: {
            color: "black",
            formatter: function(value, context) {
                    const total = context.chart.$totalizer.totals[context.dataIndex]
                    return Math.round(total)
                },
                align: 'end',
                anchor: 'end',
                display: function(ctx) {
                    return ctx.datasetIndex === ctx.chart.$totalizer.utmost
                },
            }
        },
    
        scales: {
            y: {
                stacked: true,
                ticks: {
                    beginAtZero: true
                },
                grid:{
                    display:false,
                    drawBorder:false
                },
                max: Math.round((total * 100) / 100 * 1.2)
            },
            x: {
                stacked: true,
                grid:{
                    display:true
                },
            }
        },
    };

    return (
        <>
            <div className="card m-2">
                <div className="card-body">
                    <Bar data={data} options={options} height="53%" plugins={[ChartDataLabels, totalizer]} />
                </div>
            </div>
      </>
    );
};

export default GraphCostComp;