import React, { useState, useEffect } from 'react';
import axios from '../../services/axios-psql';
import { accountService } from '../../services/auth-service';

const Rules = (props) => {

    let select = props.select;
    let headers = props.headers;

    // Initialisation des tableaux
    let neodtx_rules = [];
    let neodt2_rules = [];
    let all_rules = [];
    let selection = [];

    // Etat
    const [rules, setRules] = useState([])

    useEffect(() => {

        let loadData = () => {

            axios.get('/ocistats/securitylist', headers)
            .then((rules) => {
                setRules(rules.data);
            })
            .catch(e => {
                console.log(e);
                if (e.response.status === 401) {
                    accountService.logout();
                }
            });

        };

        loadData();
        const interval = setInterval(loadData, 1000 * 60 * 60);

        return () => clearInterval(interval);

    }, [headers]);

    // Traite le résultat de l'appel API
    if (rules.length !== 0) {   
        rules.forEach(rules => {
            let tenancy = rules.tenancy.split(' ')

            // Traduction du type
            if (rules.type === "ingress") {
                rules.type = 'entrant'
            } else if (rules.type === "egress" ) {
                rules.type = 'sortant'
            }
            
            if (tenancy[0] === 'NEODT2') {
                neodt2_rules.push(rules);
            } else {
                neodtx_rules.push(rules);
            };

            all_rules = neodt2_rules.concat(neodtx_rules);
        });

        // Défini la séléction selon l'état
        if (select === "neodt2") {
            selection = neodt2_rules;
        } else if (select === "neodtx") {
            selection = neodtx_rules;
        } else {
            selection = all_rules;
        }
    }

    return (
        <>
            <div className="col-9">
                <h1 className="text-center fs-5 fw-bold text-grey">Règle de sécurité</h1>
                <div className="card m-2 scroll">
                    <table className="table table-striped table-sm m-height">
                        <thead className={select === "neodt2" ? "card-header text-center text-light bg-neodt2 fw-bold" : (select === "neodtx" ? "card-header text-center text-light bg-neodtx fw-bold" : "card-header text-center text-light bg-all fw-bold")}>
                            <tr><th>Source</th><th>Port Min</th><th>Port Max</th><th>Type</th><th>Description</th><th>Tenancy</th></tr>
                        </thead>
                        <tbody className="card-body text-center"> 

                        {selection.length > 0 ?
                            selection.map((rules, index) => (
                                <tr key={index}>
                                    <td>{rules.source}</td>
                                    <td>{rules.min_port}</td>
                                    <td>{rules.max_port}</td>
                                    <td>{rules.type}</td>
                                    <td>{rules.description}</td>
                                    <td>{rules.tenancy}</td>
                                </tr>
                            ))
                            : <tr><td className="text-center fw-bold text-secondary" colSpan="5">Aucune règle trouvée !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default Rules;