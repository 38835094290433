import axios from 'axios';

let BASE_URL;

if (process.env.NODE_ENV === "development") {
    BASE_URL = "http://localhost:5057/api" // development
} else {
    BASE_URL = "https://api.neodt.fr/dashboard" // production
}

const instance = axios.create({
    baseURL: BASE_URL,
});

export default instance;