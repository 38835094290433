import React from 'react';
import Logo from './Logo';
import Navigation from './Navigation';
// import State from './State';

const Sidebar = () => {

    return (
        <>
            <Navigation />
            {/* <State /> */}
            <Logo />
        </>
    );
};

export default Sidebar;