import React from 'react';
import ReactTooltip from 'react-tooltip';
import { NavLink } from 'react-router-dom';
import routes from '../../routes/routes';
import { accountService } from '../../services/auth-service';

const Navigation = () => {

    const handleClick = () => {
        accountService.logout();
    }

    return (
        <>
            <ul className="nav nav-pills nav-flush flex-column mb-auto text-center border-top">
                <li className="nav-item ">
                    <NavLink to={routes.DASHBOARD} className="nav-link  border-bottom rounded-0" activeclassname="active" data-for="menu" data-tip="Dashboard">
                        <i className="bi bi-speedometer nav-icon"></i>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={routes.STATS} className="nav-link border-bottom rounded-0" activeclassname="active" data-for="menu" data-tip="OCI Statistique">
                        <i className="bi bi-graph-up-arrow nav-icon"></i>
                    </NavLink>
                </li>
                <li>
                    <button onClick={handleClick} className="nav-link border-bottom rounded-0" activeclassname="active" data-for="menu" data-tip="Logout">
                        <i className="bi bi-door-closed nav-icon"></i>
                    </button>
                </li>
            </ul>

            {/* Tooltip configuration */}
            <ReactTooltip 
                id="menu"
                place="left"
                effect="solid"
            />
        </>
    );
};

export default Navigation;