import React, { useState } from 'react';
import axios from '../services/axios-psql';
import routes from '../routes/routes';
import { useNavigate } from 'react-router-dom';
import { accountService } from '../services/auth-service';

export default function Login() {

    const navigate = useNavigate();

    const [ username, setUsername ] = useState();
    const [ password, setPassword ] = useState();
    const [ message, setMessage ] = useState();

    const handleSubmit = (e) => {
        e.preventDefault()

        const data = new URLSearchParams()
        data.append('user', username);
        data.append('password', password);


        axios.post('/user/login', data, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
        .then(res => {
            accountService.saveToken(res.data); // J'enregistre le token dans un storage local
            navigate(routes.DASHBOARD); // et je switch vers le /home
        })
        .catch((e) => {
            console.log(e);
            setMessage(e.response.data.msg);
        })
    }

  return (

    <>
        <div className='row d-flex justify-content-center align-items-center h-100'>
                
            <div className='d-flex flex-column align-items-center'>

                <div className='card bg-light' style={{width: "350px", height: "400px"}}>
                    <div className="login">
                        <div className='d-flex flex-column align-items-center pt-2'>
                            <img src='./img/neodt-logo-red.png' width="75" alt="Logo Login" />
                            <h2 className='text-center mb-5'>Bienvenue sur <br /><span className='text-danger'>Neo</span>-Dash</h2>
                        </div>

                        <div>
                            <form onSubmit={handleSubmit}>
                                <div className="input">
                                    <input required type="text" className="form-control input text-center" placeholder="Username" onChange={e => setUsername(e.target.value)} />
                                </div>
                                <div className="input mb-5">
                                    <input required type="password" className="form-control input text-center" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <button className="btn btn-danger text-white ps-5 pe-5">Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {message && <h5 className='text-danger mt-3'>{message}</h5>}
            </div>
        </div>

        <div className='text-center ms-2 mb-2 footer'>
            ©️ NeoDT - 2023 - v1.1.1
        </div>
    </>
  )
}
