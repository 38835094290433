import React from 'react';

const Title = () => {
    return (
        <div>
            <p className="m-0 fs-2 text-white">
                Dashboard <span className="text-danger">NeoDT<span className="fs-6 text-muted"> version : 1.1.2</span></span>
            </p>
        </div>
    );
};

export default Title;