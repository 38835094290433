import React, { useState } from 'react';
import GraphCostComp from '../GraphCost/GraphCostComp';
import Instance from '../Instance/Instance';
import Rules from '../Rules/Rules';
import Trend from '../Trend/Trend';
import Select from 'react-select';

const Selection = ({headers}) => {

    // Etat
    const [select, setSelect] = useState("Global");

    const handleChange = (event) => {
        setSelect(event.value)
    }

    const data = [
        {
            value: "neodt2",
            label: 'NeoDT 2'
        },
        {
            value: "neodtx",
            label: 'NeoDT X'
        },
        {
            value: "global",
            label: 'Global'
        }
    ]

    return (
        <>
            <div className="row">
                <div className="col-2">
                    <div className="row">
                        <div className="mt-2 ms-2">
                            <Select placeholder="Selectionner un Tenancy OCI" options={data} value={data.find(obj => obj.value === select)} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="row">
                        <Trend headers={headers} select={select} />
                    </div>
                </div>
                <div className="col-10">
                    <GraphCostComp headers={headers} select={select} />
                </div>
            </div>
            <hr />
            <div className="row">
                <Rules headers={headers} select={select} />
                <Instance headers={headers} select={select} />
            </div>
        </>
    );
};

export default Selection;