import React, { useEffect, useState } from 'react';
import axios from '../../services/axios-psql';
import Count from './Count';
import Table from './Table';
import Host from './Host';
import { accountService } from '../../services/auth-service';

const Alert = ({headers}) => {
        
    const [alerts, setAlerts] = useState([]);
    const [countAlerts, setCountAlerts] = useState([]);
    const [clients, setClients] = useState([]);

    useEffect(() => {

        let loadData = () => {

            axios.get('/dashboard/alertlist', headers)
            .then((alert) => {
                setAlerts(alert.data);
            })
            .catch(e => {
                console.log(e);
                if (e.response.status === 401) {
                    accountService.logout();
                }
            });

            axios.get('/dashboard/alertcount', headers)
            .then((count) => {
                setCountAlerts(count.data);
            })
            .catch(e => {
                console.log(e);
                if (e.response.status === 401) {
                    accountService.logout();
                }
            });;

            axios.get('/dashboard/hostlist', headers)
            .then((host) => {
                setClients(host.data);
            })
            .catch(e => {
                console.log(e);
                if (e.response.status === 401) {
                    accountService.logout();
                }
            });;

        };

        loadData();
        const interval = setInterval(loadData, 1000 * 60);
            
        return () => clearInterval(interval);

    }, [headers]);

    return (
        <>
            <div className='row'>
                <Count data={countAlerts} />
            </div>
            <div className='row'>
                <div className='col-10'>
                    <Table data={alerts} />
                </div>
                <div className='col-2'>
                    <Host data={clients} />
                </div>
            </div>
        </>
    );
};

export default Alert;
