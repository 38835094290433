import React from 'react';

const Count = (props) => {

    const countAlerts = props.data;

    let okCount = 0;
    let alertCount = 0;
    let criticalCount = 0;
    let unknownCount = 0;
    let acknowCount = 0;
    let downtimeCount = 0;

    if (countAlerts.length !== 0) {
        okCount = countAlerts[0].Ok;
        alertCount = countAlerts[0].Warning;
        criticalCount = countAlerts[0].Critical;
        unknownCount = countAlerts[0].Unknown;
        acknowCount = countAlerts[0].Acknowledged;
        downtimeCount = countAlerts[0].Downtime;
    }

    return (
        <>
            <div className="col card card-alert bg-warning bg-gradient">
                <div className="bg-white count-circle">
                    <p className="text-warning fw-bold fs-2">{alertCount}</p>
                </div>
                <i className="bi bi-exclamation-lg card-icon position-absolute top-0 end-0"></i>
                <p className="text-white fs-2 m-2 w3-display-bottomright">Alerte{alertCount > 1 ? 's' : ''}</p>
            </div>

            <div className="col card card-alert bg-danger">
                <div className="bg-white count-circle">
                    <p className="text-danger fw-bold fs-2">{criticalCount}</p>
                </div>
                <i className="bi bi-exclamation-triangle card-icon position-absolute top-0 end-0"></i>
                <p className="text-white fs-2 m-2 w3-display-bottomright">Critique{criticalCount > 1 ? 's' : ''}</p>
            </div>

            <div className="col card card-alert bg-secondary bg-gradient">
                <div className="bg-white count-circle">
                    <p className="text-secondary fw-bold fs-2">{unknownCount}</p>
                </div>
                <i className="bi bi-question-lg card-icon position-absolute top-0 end-0"></i>
                <p className="text-white fs-2 m-2 w3-display-bottomright">Inconnnue{unknownCount > 1 ? 's' : ''}</p>
            </div>

            <div className="col card card-alert bg-success bg-gradient">
                <div className="bg-white count-circle">
                    <p className="text-success fw-bold fs-2">{okCount}</p>
                </div>
                <i className="bi bi-hand-thumbs-up card-icon position-absolute top-0 end-0"></i>
                <p className="text-white fs-2 m-2 w3-display-bottomright">OK</p>
            </div>

            <div className="col card card-alert bg-info bg-gradient">
                <div className="bg-white count-circle">
                    <p className="text-info fw-bold fs-2">{acknowCount}</p>
                </div>
                <i className="bi bi-check2-circle card-icon position-absolute top-0 end-0"></i>
                <p className="text-white fs-2 m-2 w3-display-bottomright">Acquittée{acknowCount > 1 ? 's' : ''}</p>
            </div>

            <div className="col card card-alert bg-downtime bg-gradient">
                <div className="bg-white count-circle">
                    <p className="text-downtime fw-bold fs-2">{downtimeCount}</p>
                </div>
                <i className="bi bi-alarm card-icon position-absolute top-0 end-0"></i>
                <p className="text-white fs-2 m-2 w3-display-bottomright">Downtime{downtimeCount > 1 ? 's' : ''}</p>
            </div>
        </>
    );
};

export default Count;