import React, { useEffect, useState } from 'react';
import 'moment/locale/fr';
import moment from 'moment-timezone';

const Date = () => {

    const [dateSync, setDateSync] = useState(moment().tz("Europe/Paris").format('DD MMM YYYY'));
    const [timeSync, setTimeSync] = useState(moment().tz("Europe/Paris").format('HH:mm:ss'));

    useEffect(() => {

        // Heure
        let start = function() {
            setDateSync(moment().tz("Europe/Paris").format('DD MMM YYYY'));
            setTimeSync(moment().tz("Europe/Paris").format('HH:mm:ss'));
        }

        const interval = setInterval(start, 1000);

        return () => {clearInterval(interval)};

    }, []);

    return (
        <>
            <span className="text-info">{dateSync}</span> | {timeSync}
        </>
    );
};

export default Date;
