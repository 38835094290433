import React, { useState, useEffect } from 'react';
import axios from '../../services/axios-psql';
import { accountService } from '../../services/auth-service';

const Instance = (props) => {

    let select = props.select;
    let headers = props.headers;

    // Initialisation des tableaux
    let neodtx_instance = [];
    let neodt2_instance = [];
    let selection = [];
    let all_instance = [];

    // Etat
    const [instance, setInstance] = useState([])

    useEffect(() => {

        let loadData = () => {

            axios.get('/ocistats/instancelist', headers)
            .then((instance) => {
                setInstance(instance.data);
            })
            .catch(e => {
                console.log(e);
                if (e.response.status === 401) {
                    accountService.logout();
                }
            });

        };

        loadData();
        const interval = setInterval(loadData, 1000 * 60 * 60);

        return () => clearInterval(interval);

    }, [headers]);

    // Traite le résultat de l'appel API
    if (instance.length !== 0) {   
        instance.forEach(instance => {
            let tenancy = instance.tenancy.split(' ')
            
            if (tenancy[0] === 'NEODT2') {
                neodt2_instance.push(instance);
            } else {
                neodtx_instance.push(instance);
            };

            all_instance = neodt2_instance.concat(neodtx_instance);

        });

        // Défini la séléction selon l'état
        if (select === "neodt2") {
            selection = neodt2_instance;
        } else if (select === "neodtx"){
            selection = neodtx_instance;
        } else {
            selection = all_instance;
        }
    }

    return (
        <>
            <div className="col-3">
                <h1 className="text-center fs-5 fw-bold text-grey">Instance</h1>
                <div className="card m-2">

                    <table className="table table-sm m-height">
                        <thead className={select === "neodt2" ? "card-header text-center text-light bg-neodt2 fw-bold" : (select === "neodtx" ? "card-header text-center text-light bg-neodtx fw-bold" : "card-header text-center text-light bg-all fw-bold")}>
                            <tr><th>Instance</th><th className="text-center">Statut</th></tr>
                        </thead>
                        <tbody className="card-body"> 

                        {selection.length > 0 ?
                            selection.map((instance, index) => (
                                <tr key={index}>
                                        <td className="text-center fw-bold text-grey">{instance.name}</td>
                                        {instance.state === "RUNNING" ?
                                          <td className="text-center"><span className="badge bg-success">{instance.state}</span></td>
                                          : <td className="text-center"><span className="badge bg-danger blinks">{instance.state}</span></td>}
                                    </tr>
                                ))
                                : <tr><td className="text-center fw-bold text-secondary" colSpan="2">Aucune instance trouvée !</td></tr>}

                        </tbody>
                    </table>
                    
                </div>
            </div>
        </>
    );
};

export default Instance;