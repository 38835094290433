import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Title from './Title';
import WeatherDate from './Weather';
import Date from './Date';

const Headers = () => {

    const [weather, setWeather] = useState({icon: 0});

    useEffect(() => {

        // Météo
        let loadData = () => {
            axios.get('https://api.openweathermap.org/data/2.5/weather?id=6543862&lang=fr&units=metric&appid=c823c577ca8ea3c71674d2c8cf026a79'
            ).then((result) => {
                setWeather(result.data)
            }).catch((error) => {
                console.log(error)
            });
        };

        loadData();
        const interval = setInterval(loadData, 1000 * 60 * 60);

        return () => {clearInterval(interval)};

    }, []);

    return (
        <>
            <nav className="navbar bg-dark">
                <Title />
                <div className="text-white fs-2 m-0 me-1">
                    <WeatherDate data={weather} /> | <Date />
                </div>
            </nav>
        </>
    );
};

export default Headers;
