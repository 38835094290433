import React, { useState, useEffect } from 'react';
import axios from '../../services/axios-psql';
import { Line } from 'react-chartjs-2';
import { accountService } from '../../services/auth-service';

const Trend = (props) => {

    // VARIABLE //
    let select = props.select;
    let headers = props.headers;
    let select_name;

    if (select === "neodt2") {
        select_name = "NeoDT 2"
    } else if (select === "neodtx") {
        select_name = "NeoDT X"
    } else {
        select_name = "Global"
    }

    // // Etat
    const [trend, setTrend] = useState([]);
    const [trendGraph, setTrendGraph] = useState([]);

    // Appel API pour récuperer les données
    useEffect(() => {

        let loadData = () => {

            axios('/ocistats/trend?tenancy=' + select, headers)
            .then((trend) => {
                console.log(trend.data)
                setTrend(trend.data);
            })
            .catch(e => {
                console.log(e);
                if (e.response.status === 401) {
                    accountService.logout();
                }
            });

            axios('/ocistats/trendgraph?tenancy=' + select, headers)
            .then((trendGraph) => {
                setTrendGraph(trendGraph.data);
            })
            .catch(e => {
                console.log(e);
                if (e.response.status === 401) {
                    accountService.logout();
                }
            });

        };

        loadData();
        const interval = setInterval(loadData, 1000 * 60 * 60);
            
        return () => clearInterval(interval);

    }, [headers, select]);
    // Appel API pour récuperer les données

    // PARAM. GRAPH //
    const data = {
        labels: trendGraph.label,
        datasets: [
          {
            data: trendGraph.cost,
            fill: false,
            backgroundColor: trendGraph.backgroundColor,
            borderColor: trendGraph.borderColor,
            tension: 0.5,
            pointRadius: 4,
            pointHoverRadius: 6,
            pointBackgroundColor: trendGraph.backgroundColor,
            pointBorderColor: trendGraph.borderColor,
          },
        ],
      };
      
      const options = {
        plugins: {
            legend: {
                display:false
            }
        },
        scales: {
            x: {
                grid:{
                 display:false,
                 drawBorder:false
                },
                ticks:{
                    display:true,
                  },
               },
            y: {
                grid:{
                 display:false,
                 drawBorder:false
                },
                ticks:{
                  display:false,
                },
               }
        }
      };
    // PARAM. GRAPH //

    return (
        <>
            <div className="card m-2 p-0 text-dark">
                <h1 className={select === "neodt2" ? "card-header text-center text-light bg-neodt2 fw-bold fs-5" : (select === "neodtx" ? "card-header text-center text-light bg-neodtx fw-bold fs-5" : "card-header text-center text-light bg-all fw-bold fs-5")}>Tendance {select_name}</h1>
                <div className="card-body">
                    <div className="fs-5 text-center"><span className={trend.diff > 0 ? "fw-bold up-color" : "fw-bold down-color"}>{trend.diff > 0 ? "+" + trend.diff + "€" : trend.diff + "€"  } </span><span className="fst-italic text-grey">{"(" + (trend.diff_percent + " %)")}</span></div>
                    <Line className="mt-3" data={data} options={options} height="125%" />
                </div>
            </div>
        </>
    );
};

export default Trend;