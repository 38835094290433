import React from 'react';

const NotFound = () => {
    return (
        <div className='d-flex justify-content-center align-items-center h-100'>
            <img src="./img/404.png" width="800" alt="Not Found" />
        </div>
    );
};

export default NotFound;